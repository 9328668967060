<template>
  <!--  <div class="col-12">-->
  <AiGenreResult />
  <!--  </div>-->
</template>

<script>
import AiGenreResult from "../components/AiGenreResult";
export default {
  name: "AiGenre",
  components: {
    AiGenreResult,
  },
};
</script>

<style scoped></style>
