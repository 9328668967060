<template>
  <cardToolbar
    v-loading="loading"
    class="genre-result"
    title="AI Genre"
    description="AI Genre Result"
    :show-toolbar-buttons="false"
  >
    <template v-slot:body>
      <div
        v-for="(item, index) in genresInfo"
        :key="index"
        class="position-relative"
      >
        <div
          @click="routeToDiscovery(item.id)"
          class="position-absolute cursor-pointer"
          :style="{
            left: parseInt(item.x) + 'px',
            top: parseInt(item.y) + 'px',
            color: item.hex_colour,
          }"
        >
          {{ item.name }}
        </div>
      </div>
      <!--      <div class="position-relative"></div>-->
    </template>
  </cardToolbar>
</template>

<script>
import cardToolbar from "@/common/components/CardToolbar";
import { getAllGenres } from "@/api/genreResult";

export default {
  name: "AiGenreResult",
  components: {
    cardToolbar,
  },
  data() {
    return {
      genresInfo: [],
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const { data } = await getAllGenres();
    this.genresInfo = data;
    this.loading = false;
  },
  methods: {
    routeToDiscovery(genreId) {
      if (genreId) {
        window.open(`/discover?genre=${genreId}`, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.genre-result {
  height: 98%;
  overflow-y: scroll;
}
.post {
  top: 120px;
  left: 120px;
}
</style>
